<template>
      <div id="app">
            <toaster></toaster>
            <MottoHeader />
            <transition name="fade" mode="out-in">
                  <router-view></router-view>
            </transition>
            <Overlay v-show="isLoading"/>
      </div>
</template>

<script>
import MottoHeader from "@/components/common/header/Header";
export default {
      name: "App",
      components: { MottoHeader },
      data(){
            return {
                  item: { model: 'Model 01', name: '302', bedrooms: 1, bathrooms: 3, sq: 35 }
            }
      },
      computed: {
            isLoading: {
                  get(){
                        return this.$store.state.isLoading;
                  }
            }
      }
};
</script>

<style lang="scss">
body {
      position: relative;
      font-family: $vaud-normal;
      background: $main;
      font-size: $font-size;
      margin: 0;
}

#app {
      width: 100%;
      height: 100vh;
      overflow: hidden;
      // padding: 0 18px;

      @media screen and (min-width: 3000px){
            overflow: auto;
      }

      @media screen and (max-width: 1366px){
            overflow: auto;
      }
}

.fade-enter-active,
.fade-leave-active {
      transition-property: opacity;
      transition-duration: 0.25s;
}

.fade-enter-active {
      transition-delay: 0.25s;
}

.fade-enter,
.fade-leave-active {
      opacity: 0;
}

.modal-shown {
      position: relative;
      .overlay {
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 20;
            width: 100%;
            height: 100vh;
            overflow: hidden;
            backdrop-filter: blur(2px);
      }

      .modal-body {
            img {
                  width: 100%
            }
      }
}
// .modal-shown > * {
//       filter: blur(3px);
// }
// .modal-backdrop {
//       filter: blur(0);
// }
</style>
