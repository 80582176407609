import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);
function lazyLoad(view){
      return() => import(`@/views/${view}.vue`)
}

const routes = [
      {
            path: '/',
            name: 'Home',
            component: lazyLoad('Home'),
            props: true
      },
      {
            path: "*",
            name: "Error404",
            meta: { layout: "default", title: "Page introuvable" },
            component: lazyLoad('Error404')
      },
      {
            path: "/neighbourhood",
            name: "Neighbourhood",
            meta: { layout: "default", title: "Neighbourhood" },
            component: lazyLoad('Neighbourhood')
      },
      {
            path: "/features",
            name: "Features",
            meta: { layout: "default", title: "Features" },
            component: lazyLoad('Features')
      },
      {
            path: "/amenities",
            name: "Amenities",
            meta: { layout: "default", title: "Amenities" },
            component: lazyLoad('Amenities')
      },
      {
            path: "/building",
            name: "Building",
            meta: { layout: "default", title: "Building" },
            component: lazyLoad('Building')
      },
      {
            path: "/floorplans",
            name: "Floorplans",
            meta: { layout: "default", title: "Floorplans" },
            component: lazyLoad('Floorplans')
      },
      {
            path: "/floorplans/:id",
            name: "SingleFloorPlan",
            meta: { layout: "default", title: "Single Floor" },
            component: lazyLoad("floorplans/SingleFloor")
      },
      {
            path: "/gallery",
            name: "Gallery",
            meta: { layout: "default", title: "Gallery" },
            component: lazyLoad('Gallery')
      },
      {
            path: "/builder",
            name: "Builder",
            meta: { layout: "default", title: "Builder" },
            component: lazyLoad('Builder')
      },
      {
            path: "/test",
            name: "Test",
            meta: { layout: "default", title: "Test" },
            component: lazyLoad('Test')
      },
      // {
      //       path: '/learn',
      //       name: 'Learn',
      //       component: () => import('../views/Learn.vue'),
      //       props: true,
      // }
];

const router = new VueRouter({
      mode: 'history',
      base: process.env.BASE_URL,
      linkActiveClass: 'active-link',
      routes
});

export default router;