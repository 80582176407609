import Vue from 'vue'
import App from './App.vue';
import VueWow from 'vue-wow';
import animated from 'animate.css';
import store from "@/store";
import Overlay from "@b/overlay/Overlay";
import '@/components';

Vue.component("Overlay", Overlay);

import router from "@/router";

Vue.config.productionTip = false;
export const bus = new Vue();

Vue.mixin({
      methods: {
            goTo(route) {
                  if (this.$route.path !== route) {
                        this.$router.push(route);
                  }
            }
      },
});

Vue.use(VueWow).use(animated);

new Vue({
      router,
      store,
      render: h => h(App)
}).$mount('#app')
