<template>
      <div class="overlay">
            <div class="loader"></div>
      </div>
</template>
<script>
export default {
      name: "overlay",
};
</script>

<style lang="scss">
@import "Overlay";
</style>