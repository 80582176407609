<template>
      <button :class="[`btn-${size}`, variant]" :style="style" @click="$emit('click')" class="btn">
            <div class="wrapper d-flex justify-content-center align-items-center">
                  <img v-if="icon" :src="require(`@/assets/icons/svg/${icon}.svg`)" :alt="icon">
                  <span class="title" :class="{'ml-10': icon}">{{title}}</span>
            </div>
      </button>
</template>

<script>
      export default {
            name: 'btn',
            props: {
                  title: {
                        type: String, 
                        default: ""
                  },
                  radius: {
                        type: String, 
                        default: ""
                  },
                  variant: {
                        type: String,
                        default: ""
                  },
                  icon: {
                        type: String,
                        default: ""
                  },
                  size: {
                        type: String,
                        default: ""
                  }
            },
            computed: {
                  style () {
                        return `border-radius: ${this.radius ? this.radius + 'px' : '24px'};`;
                  }
            },
            methods: {
                  callback: function () {
                        this.$emit("click");
                  },
            }
      }
</script>

<style lang='scss'>
@import 'Button';
</style>