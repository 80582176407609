<template>
      <div class="header row align-items-center">
            <div class="site-brand col-10 md:col-1 p-0">
                  <img class="logo" v-lazy-src="require(`@/assets/images/desktop/png/Motto white@BW1x-1.png`)" width="90px" height="26px" alt="logo">
            </div>
            <div class="links md:d-flex md:col-10 d-none justify-content-end p-0">
                  <nav>
                        <router-link v-for="(route, index) in routes" :key="generateUniqueId(index + 5)" :to="route.path">{{route.title}}</router-link>
                  </nav>      
            </div>
            <div class="sierra d-none md:d-flex md:col-1 justify-content-end p-0">
                  <Btn size="md" variant="primary" radius="0" title="sierra"/>
            </div>
            <div class="menu-toggler col-2 d-flex justify-content-end md:d-none">
                  <Btn class="menu" variant="transparent" icon="menu-hamb" @click="showMenu = true"/>
            </div>
            <transition name="fade">
                  <div @click="close($event)" v-if="showMenu" class="menu-bar full-width full-height" :class="{'shown-menu': showMenu}">
                        <div class="menu-items d-flex justify-content-center">
                              <Btn class="close-menu  mt-15" variant="transparent" icon="menu-hamb" @click="showMenu = false"/>
                              <nav class="menu-items-box">
                                    <router-link v-for="(route, index) in routes" :key="generateUniqueId(index + 5)" :to="route.path">{{route.title}}</router-link>
                                    <Btn size="md" variant="primary" radius="0" title="sierra"/>
                              </nav>
                        </div>
                  </div>
            </transition>
      </div>
</template>

<script>
      import directive from '@/directives/lazy-src';
      import { generateUniqueId } from '@/helpers/helpers';
      import Btn from '@/components/base/button/Button';
      export default {
            directives: {directive},
            name: 'motto-header',
            components: {Btn},
            data(){
                  return {
                        routes: [
                              {path: '/', title: 'Home'},
                              {path: '/building', title: 'Building'},
                              {path: '/floorplans', title: 'Floorplans'},
                              {path: '/amenities', title: 'Amenities'},
                              {path: '/features', title: 'Features & Finishes'},
                              {path: '/neighbourhood', title: 'Neighbourhood'},
                              {path: '/gallery', title: 'Gallery'},
                              {path: '/builder', title: 'Builder'}
                        ],
                        showMenu: false
                  }
            },
            methods: {
                  generateUniqueId,
                  close(e){
                        if(e.target.className.includes('menu-bar')) {
                              this.showMenu = false
                        }
                  }
            },
            watch:{
                  showMenu(val){
                        if(val){
                              // document.querySelector('html').style.overflowY = 'hidden';
                              document.querySelector('body').style.overflowY = 'hidden';
                        } else if(['/features', '/builder'].includes(this.$route.path)) {
                              document.querySelector('body').style.overflowY = 'scroll';
                              // document.querySelector('html').style.overflowY = 'scroll';
                        }
                  },
                  $route(){
                        this.showMenu = false;
                  }
            }
      }
</script>

<style lang='scss'>
      @import 'Header';
//       content: "";
//     width: 1px;
//     height: 1076%;
//     background: #ddd;
//     position: absolute;
//     left: 50%;
//     top: -387px;
//     transform: translate(50%, 50%);
</style>